<router>
{
    name: 'Dashboard'
}
</router>
<template lang="pug">
v-app
    v-main.white()
        v-app-bar.topBar(color='brand', dark, app)
            a.d-flex(@click='goHome', justify='center', align='center')
                v-img.mr-4.mt-1(src='/DorsettControls_Symbol_White.png', max-height='24', max-width='30')
            v-btn.white--text(text, @click='goHome') Home
            v-spacer
            v-select.mt-8.ml-4(label='Select Site', outlined, :items='this.customers', item-text='name', item-value='id', v-model='selectedCustomer', v-if='this.customers.length > 1', dense)
            v-spacer
            v-menu(offset-y)
                template(v-slot:activator='{on, attrs}')
                    v-btn(text, v-on='on') {{ `${fullUser.firstName} ${fullUser.lastName}` }}
                        v-icon arrow_drop_down
                v-list(light)
                    v-list-item(@click='showUserEditor = true')
                        v-list-item-title Profile
                    v-list-item(text, @click='logout')
                        v-list-item-title Logout

        router-view

        v-footer(app, color='white', :elevation='6', padless)
            v-col.text-center(cols='12')
                .copyright.brand--text &copy; {{ `${currentYear} Dorsett Technologies Inc. DBA Dorsett Controls. All Rights Reserved.` }}
                a.brand--text(absolute, right, href='https://dorsettcontrols.com/support') https://dorsettcontrols.com/support

    v-dialog(v-model='showUserEditor', max-width='500px')
        v-card(light)
            v-card-title Edit Profile
            v-card-text
                UserEditor(:username='user.username', @userUpdated='handleUserUpdated', :customerId='currentCustomerId')

    v-dialog(v-model='showUserEditor', max-width='500px')
        v-card(light)
            v-card-title Edit Profile
            v-card-text
                UserEditor(:username='user.username', @userUpdated='handleUserUpdated', :customerId='currentCustomerId')

    v-dialog(v-model='showUserEditor', max-width='500px')
        v-card(light)
            v-card-title Edit Profile
            v-card-text
                UserEditor(:username='user.username', @userUpdated='handleUserUpdated', :customerId='currentCustomerId')

    v-overlay(v-if='loading')
        v-row
            v-col.text-center
                v-progress-circular(indeterminate size='128', color='brand')
                .text-h5 Loading...
    v-snackbar(v-model='snackbar', bottom, :timeout='snackbarTimeout', :color='snackbarColor') {{ snackbarText }}
        template(v-slot:action)
            v-btn(dark, text, @click='snackbar = false') Close

</template>
<script>
import dti from 'dti';
import { nanoid } from 'nanoid';
import UserEditor from '@/components/portal/usereditor.vue';
dti.makeuuid = (len = 10) => {
    return nanoid(len);
};

export default {
    components: {
        UserEditor
    },
    data () {
        return {
            loading: true,
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'red',
            snackbarTimeout: 3000,

            selectedCustomer: null,
            user: {
                firstName: '',
                customers: []
            },
            showUserEditor: false
        };
    },
    methods: {
        gotoPage (item) {
            if (!item) {
                return;
            }
            this.$router.push({
                path: typeof item === 'object' ? item.link : item
            });
        },
        logout () {
            this.$store.dispatch('logout');
        },
        async getFullUserData () {
            const result = await this.post('getUser', {
                username: this.fullUser.username
            });
            this.user = result;
            if (this.customers.length) {
                this.selectedCustomer = this.customers[0].id;
            }
        },
        handleUserUpdated () {
            this.showUserEditor = false;
        },
        goHome () {
            this.gotoPage('/home');
        }
    },
    computed: {
        errorMessage () {
            return this.$store.getters.errorMessage;
        },
        loggedIn () {
            return this.$store.getters.loggedIn;
        },
        fullUser () {
            return this.$store.state.fullUser;
        },
        customers () {
            return this.user.customers;
        },
        currentYear () {
            return new Date().getFullYear();
        },
        currentCustomerId () {
            return this.$store.state.customerId;
        }
    },
    watch: {
        async loggedIn (val) {
            if (val) {
                this.$nextTick(() => {
                    this.loading = false;
                });
            } else {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.gotoPage('/login');
            }
        },
        errorMessage (val) {
            if (val && val !== '') {
                this.snackbarColor = 'red';
                this.snackbarText = val;
                this.snackbar = true;
                setTimeout(() => {
                    this.snackbarText = '';
                }, this.snackbarTimeout + 1000);
            }
        },
        selectedCustomer: {
            immediate: true,
            handler (newValue) {
                this.$store.commit('setCustomer', newValue);
                this.$router.push({path: this.$route.path, query: {customer: newValue}});
            }
        }
    },
    beforeCreate () {
    },
    mounted () {
        window.pvm = this;
        setTimeout(async () => {
            if (this.loggedIn) {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.getFullUserData();
            } else {
                this.$nextTick(() => {
                    this.loading = false;
                });
                this.gotoPage('/login');
            }
        }, 2000);
    }
};
</script>

<style scoped lang="scss">
.v-system-bar {
    text-align: center;
    bottom: 0;
    top: unset;
}

/**=== dots5 ===*/
#dots5 {
    display: flex;
    position: absolute;
    justify-content: space-between;
    top: 50%;
    left: 50%;
    height: 50px;
    width: 75px;
    transform: translate(-50%, -50%);
    // margin: -25px 0 0 -25px;
}

#dots5 span {
    // position: absolute;
    width: 10px;
    height: 10px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    -webkit-animation: dots5 1s infinite ease-in-out;
                    animation: dots5 1s infinite ease-in-out;
}

@for $c from 1 through 10 {
    #dots5 span:nth-child(#{$c}) {
        animation-delay: -1s + (.1 * ($c));
    }
}

$dcLight: #009eda;
$dcDark: #00355c;

@keyframes dots5 {
    0% {
        transform: translateY(0px);
        background: white;
    }
    33% {
        transform: translateY(10px);
        background: $dcLight;
    }
    66% {
        transform: translateY(10px);
        background: $dcDark;
    }
    100% {
        transform: translateY(0px);
        background: white;
    }
}
/** END of dots5 */
</style>
<style lang="scss">
</style>
